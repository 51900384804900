
/*!
 * @see https://github.com/apvarun/toastify-js
 */
.toastify {
  display: inline-block;
  // background: $primary;
  box-shadow: 0 3px 6px -1px rgba(0, 0, 0, 0.2), 0 10px 36px -4px rgba(70, 70, 70, 0.3);
  position: fixed;
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  text-decoration: none;
  max-width: calc(50% - 20px);
  z-index: 2147483647;
}

.toastify.on {
  opacity: 0.95;
}

.toastify.on:hover {
  opacity: 1;
}

.toastify-right {
  right: 15px;
}

.toastify-left {
  left: 15px;
}

.toastify-top {
  top: -150px;
}

.toastify-bottom {
  bottom: -150px;
}

.toastify-center {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  max-width: fit-content;
}

@media only screen and (max-width: 360px) {
  .toastify-right, .toastify-left {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    max-width: fit-content;
  }
}

.toast__progressbar {
  transition: width .1s linear;
}
